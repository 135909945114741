(function () {
	'use strict';
	angular
		.module('crypt', ['core'])
		.factory('hash', ['$http', 'seedcodeCalendar', 'utilities', hash])
		.factory('crypt', [
			'$location',
			'$http',
			'$timeout',
			'seedcodeCalendar',
			'utilities',
			'hash',
			'manageSettings',
			'urlParameters',
			cryptService,
		]);

	function hash($http, seedcodeCalendar, utilities) {
		var hashResult = {};
		var dialogsPrevented;

		return {
			getPreventDialogs: getPreventDialogs,
			setPreventDialogs: setPreventDialogs,
			init: init,
			get: get,
			set: set,
			update: update,
			getExpirationDays: getExpirationDays,
			getActivation: getActivation,
			gotoPurchase: gotoPurchase,
			getBillingPortalURL: getBillingPortalURL,
			getPurchaseURL: getPurchaseURL,
			getManageURL: getManageURL,
		};

		function setPreventDialogs(prevent) {
			dialogsPrevented = prevent;
		}

		function getPreventDialogs() {
			return dialogsPrevented;
		}

		function init() {
			for (var property in hashResult) {
				hashResult[property] = null;
			}

			return hashResult;
		}

		function get() {
			return hashResult;
		}
		function set(hashData) {
			hashResult = hashData;
			return hashResult;
		}
		function update(property, value) {
			var hashResult = get();
			hashResult[property] = value;
		}

		function getExpirationDays() {
			var today = new Date();
			today.setHours(0, 0, 0, 0);
			today = today.getTime();

			// We can allow a negative expiration days as we want to show if there is a problem (another option is to set to zero if negative)
			return hashResult.expiresDate
				? utilities.numberToDays(hashResult.expiresDate - today)
				: ''; //Math.abs(utilities.numberToDays(hashResult.expiresDate - today)) : '';
		}

		function getActivation() {
			var settings = seedcodeCalendar.get('settings');
			return settings.activation;
		}

		function gotoPurchase(callback) {
			var platform = utilities.getDBKPlatform();
			var purchaseURL = getPurchaseURL();

			var modalTitle = 'Waiting for Purchase';
			var modalMessage =
				'Purchase taking place in a new window. Choose an option below once complete.';
			utilities.showModal(
				modalTitle,
				modalMessage,
				'Cancel',
				null,
				"I've Purchased",
				function () {
					seedcodeCalendar.init('activation-success', true);
					if (callback) {
						callback();
					}
				}
			);

			// if (platform === 'dbkfmjs') {
			// 	utilities.performFileMakerScript('Modal Webviewer - DayBack', {url: purchaseURL, searchTerm: 'purchase-success', height: 'window', width: 'window', checkDelay: 1}, function() {
			// 		// Callback goes here. Currently we don't have a callback but we need to pass one so the script gets everything formatted correctly
			// 	}, true);
			// }
			// else if (platform === 'dbkfmwd') {
			// 	utilities.performFileMakerScript('Open URL - DayBack', purchaseURL, null);
			// }
			// else {
			utilities.help(purchaseURL, purchaseURL, true);
			// }
		}

		function getPurchaseURL(isDBKFM) {
			var purchaseURL;
			//Old purchase link
			//https://dayback.onfastspring.com/dayback-calendar-billed-yearly
			//https://dayback.onfastspring.com/dayback-calendar-billed-monthly
			var config = seedcodeCalendar.get('config');

			if (!isDBKFM) {
				purchaseURL = _CONFIG.DBK_PURCHASE_URL;

				if (!config) {
					return '';
				}
				// purchaseURL = 'https://dayback.chargifypay.com/subscribe/crzstcqftngx/dayback-yearly';
				// purchaseURL = 'https://dayback.chargifypay.com/subscribe/5tzk8pgr3dgz/dayback-monthly';
				return (
					purchaseURL +
					'?' +
					'components[' +
					_CONFIG.DBK_YEARLY_COMPONENT_ID +
					'][allocated_quantity]=' +
					hashResult.userLimit +
					'&' + // Yearly
					'components[' +
					_CONFIG.DBK_MONTHLY_COMPONENT_ID +
					'][allocated_quantity]=' +
					hashResult.userLimit +
					'&' + // Monthly
					'components[' +
					_CONFIG.DBK_MONTHLY_ESSENTIALS_COMPONENT_ID +
					'][allocated_quantity]=' +
					hashResult.userLimit +
					'&' + // Monthly essentials
					'components[' +
					_CONFIG.DBK_MONTHLY_PLUS_COMPONENT_ID +
					'][allocated_quantity]=' +
					hashResult.userLimit +
					'&' + // Monthly plus
					'components[' +
					_CONFIG.DBK_YEARLY_ESSENTIALS_COMPONENT_ID +
					'][allocated_quantity]=' +
					hashResult.userLimit +
					'&' + // Yearly essentials
					'components[' +
					_CONFIG.DBK_YEARLY_PLUS_COMPONENT_ID +
					'][allocated_quantity]=' +
					hashResult.userLimit +
					'&' + // Yearly plus
					'qty=' +
					hashResult.userLimit +
					'&' +
					'reference=' +
					encodeURIComponent(config.userID) +
					'&' +
					'groupID=' +
					encodeURIComponent(config.groupID) +
					'&' +
					'organization=' +
					encodeURIComponent(config.groupName) +
					'&' +
					'trialID=' +
					encodeURIComponent(hashResult.subscriptionID) +
					'&' +
					'email=' +
					encodeURIComponent(config.email) +
					'&' +
					'first_name=' +
					encodeURIComponent(config.firstName) +
					'&' +
					'last_name=' +
					encodeURIComponent(config.lastName)
				);
			} else {
				return 'http://www.seedcode.com/daybackforfilemaker/purchase/';
				//'https://docs.dayback.com/article/134-add-more-licenses'
			}
		}
		function getManageURL() {
			return hashResult.subscriptionID
				? 'https://dayback.onfastspring.com/account'
				: hashResult.subscriptionURL;
		}

		function getBillingPortalURL(successCallback, errorCallback) {
			var userID = hashResult.userID;

			// Ther should be no billing portal url for a trial
			if (
				hashResult.type === 'trial' ||
				!hashResult.hasActivationObject
			) {
				errorCallback();
				return;
			}

			// Activate subscription
			$http({
				method: 'GET',
				url:
					_CONFIG.DBK_API_BASEURL +
					'billingportal/url' +
					'?userID=' +
					userID,
				data: {},
			})
				.then(function (response) {
					successCallback(response.data);
				})
				.catch(function (error) {
					errorCallback();
				});
		}
	}

	function cryptService(
		$location,
		$http,
		$timeout,
		seedcodeCalendar,
		utilities,
		hash,
		manageSettings,
		urlParameters
	) {
		var subscriptionVerified;

		return {
			initHash: initHash,
			typeFromState: typeFromState,
			inactiveAccount: inactiveAccount,
			verifyHash: verifyHash,
			writeHash: writeHash,
			activate: activate,
			checkHashExpired: checkHashExpired,
			checkForUpdate: checkForUpdate,
			getUpgradeDirectory: getUpgradeDirectory,
			resetUserLicense: resetUserLicense,
		};

		function initHash() {
			var hash = hash.init();
			return hash;
		}

		function typeFromState(state, hasSubscription) {
			var type;
			if (
				state === 'trialing' ||
				state === 'trial_ended' ||
				!hasSubscription
			) {
				type = 'trial';
			} else if (crypt.hasOverdueState(state)) {
				type = 'overdue';
			} else if (crypt.hasValidSubscriptionState(state)) {
				type = 'subscription';
			} else {
				type = 'inactive';
			}
			return type;
		}

		function getPlanLevel(productHandle) {
			// Plan levels
			// 0 - Essentials
			// 1 - DayBack
			// 2 - Plus

			if (fbk.isSalesforce()) {
				return 2;
			} else if (
				productHandle &&
				productHandle.indexOf('-essentials-') > -1
			) {
				return 0;
			} else if (productHandle && productHandle.indexOf('-plus-') > -1) {
				return 2;
			} else {
				return 1;
			}
		}

		function checkActiveSessions(callback) {
			var config = seedcodeCalendar.get('config');
			// Check for config object in case this is attempting to load just after
			// switching to admin settings and the config object has been reset
			if (!config) {
				return;
			}

			var device = seedcodeCalendar.get('device');
			var activeSessionLimit = config.activeSessionLimit
				? Number(config.activeSessionLimit)
				: 3;
			var sessionExpiresDays = 7; // How many days we assume a session is dead
			var oldestSessionDate;
			var oldestSessionDateDisplay;
			var title = 'Too Many Devices';
			var message;
			var config = seedcodeCalendar.get('config');
			var linkedGroupToken = urlParameters.userGroupToken;
			var linkedAccount = urlParameters.userEmail;

			var actualSessionCount;
			var sessionLimitDiff;
			var sessionArray = [];
			var oldestSessions = [];
			var now = moment();
			var sessionDate;
			var oldSessionCount = 0;

			// Don't evaluate if this is a true sign in or if we are having issues retrieving the account
			if (
				!config.account ||
				config.account.indexOf('true-cal.com') > -1
			) {
				return;
			}

			// Prevent check for specific customer group that keeps running into problems: https://secure.helpscout.net/conversation/1441646186
			if (config.groupID === 'NQUTlVSnujNrsc4ilPf49fc3vNB3') {
				return;
			}

			manageSettings.getSessionCount(
				config.groupID,
				config.userID,
				function (activeSessions) {
					if (activeSessions > activeSessionLimit) {
						manageSettings.getSessions(
							config.groupID,
							config.userID,
							function (sessions) {
								actualSessionCount =
									Object.keys(sessions).length;
								// If the actual session count isn't over the limit we can return
								if (actualSessionCount <= activeSessionLimit) {
									return;
								}

								sessionLimitDiff =
									actualSessionCount - activeSessionLimit;

								// build array so it can be sorted
								for (var property in sessions) {
									// if (sessions[property].id !== device.id) {
									sessionArray.push(sessions[property]);
									// }
								}

								// sort the array
								sessionArray.sort(function (a, b) {
									return a.timestamp - b.timestamp;
								});

								// grab array items that exceed the session device limit
								for (var i = 0; i < sessionLimitDiff; i++) {
									// continue if not a valid array item
									if (!sessionArray[i]) {
										continue;
									}
									// get date of current session
									sessionDate = moment(
										sessionArray[i].timestamp
									);
									// if the session date is beyond our threshold let's remove session record and ignore sign out
									if (
										now.diff(sessionDate, 'days') >
										sessionExpiresDays
									) {
										// If the oldest session is beyond our expired threshold we assume it is
										// a stale session so we remove the record and move on
										removeSessionDevice(sessionArray[i].id);
									} else {
										oldSessionCount++;
										// add item to oldest sessions object
										oldestSessions.push(sessionArray[i].id);
										// record the oldest session date
										oldestSessionDate = moment(
											sessionArray[i].timestamp
										);
										oldestSessionDateDisplay =
											oldestSessionDate.format('lll');
									}
								}

								if (!oldSessionCount) {
									// there are no sessions to remove
									return;
								}

								if (linkedGroupToken && linkedAccount) {
									message =
										'There are too many devices signed in with this account. Please close DayBack on another device and reload.';
									utilities.showModal(
										title,
										message,
										null,
										null,
										'Reload',
										function () {
											window.location.reload();
										}
									);
								} else {
									if (oldSessionCount === 1) {
										message =
											'There are too many devices signed in with this account. Would you like to continue and sign out the device last signed in on ' +
											oldestSessionDateDisplay +
											'?';
									} else {
										message =
											'There are too many devices signed in with this account. Would you like to continue and sign out of the ' +
											oldSessionCount +
											' oldest sessions?';
									}
									utilities.showModal(
										title,
										message,
										'Cancel',
										function () {
											signOutDevice([device.id], true);
										},
										'Continue',
										function () {
											signOutDevice(oldestSessions);
										}
									);
								}
							}
						);
					}
				}
			);

			function removeSessionDevice(deviceID) {
				manageSettings.setSession(
					config.groupID,
					config.userID,
					deviceID,
					null,
					null
				);
			}
		}

		function signOutDevice(deviceIDs, signOutSelf) {
			// DeviceIDs is an array of device session id's
			var config = seedcodeCalendar.get('config');
			manageSettings.setRemoteDeviceSignOut(
				config.groupID,
				config.userID,
				deviceIDs,
				signOutSelf,
				function (sessions) {
					// Could run callback here
				}
			);
		}

		function inactiveAccount() {
			var popover = {
				id: 'inactive',
				controller: 'ActivationCtrl',
				container: $('body'),
				type: 'modal', // modal or popover
				width: 750,
				// positionX: e.pageX,
				// positionY: e.pageY,
				destroy: true,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: '',
				show: true,
			};

			utilities.popover(
				popover,
				'<div ng-include="\'app/activation/account-inactive.html\'"></div>'
			);
		}

		function verifyHash(
			hashToVerify,
			update,
			viewCallback,
			callback,
			subscriptionExpired
		) {
			var changed = false;
			var trialStart;
			var hashResult;
			var activationObj = hash.getActivation();
			var platform = utilities.getDBKPlatform();
			var expiresDateNotificationBuffer = 1;

			var showPopover = function (expired) {
				if (hash.getPreventDialogs() && !expired) {
					return;
				}
				var popover = {
					id: 'activation',
					controller: 'ActivationCtrl',
					container: $('body'),
					type: 'modal', // modal or popover
					width: 750,
					// positionX: e.pageX,
					// positionY: e.pageY,
					destroy: true,
					onShow: '',
					onShown: '',
					onHide: '',
					onHidden: '',
					show: true,
				};

				utilities.popover(
					popover,
					'<div ng-include="\'app/activation/admin-settings.html\'"></div>'
				);
			};

			var successCallback = function (newHash, firstRun) {
				//If this is first run then show trial start dialog
				if (firstRun) {
					trialStart = firstRun;
					$timeout(function () {
						showPopover();
						//Set trial start property so we no longer assume first run
						manageSettings.setActivation('trialStart', null, null);
					}, 0);
				}
				if (activationObj) {
					// Could do something here for activation object
				} else if (newHash !== hashToVerify || update) {
					changed = true;
					writeHash(newHash);
				}
			};

			var expiredCallback = function (type) {
				//If this is a subscription we only want to show expiration after subscription check
				if (
					type === 'subscription' &&
					!subscriptionExpired &&
					!activationObj
				) {
					return;
				}
				$timeout(function () {
					showPopover(true);
				}, 0);
			};

			var overdueCallback = function (type) {
				//If this is a subscription we only want to show expiration after subscription check
				if (hash.getPreventDialogs()) {
					return;
				}

				$timeout(function () {
					showPopover(true);
					hash.setPreventDialogs(true);
				}, 0);
			};
			var trialCallback = function () {
				if (hash.getPreventDialogs()) {
					return;
				}
				var popover = {
					id: 'trial',
					controller: 'ActivationCtrl',
					container: $('body'),
					type: 'modal', // modal or popover
					width: 750,
					// positionX: e.pageX,
					// positionY: e.pageY,
					destroy: true,
					onShow: '',
					onShown: '',
					onHide: '',
					onHidden: '',
					show: true,
				};

				utilities.popover(
					popover,
					'<div ng-include="\'app/activation/trial-mode-dialog.html\'"></div>'
				);
			};

			var updateCallback = function () {
				var popover = {
					id: 'updated',
					controller: 'ActivationCtrl',
					container: $('body'),
					type: 'modal', // modal or popover
					width: 750,
					// positionX: e.pageX,
					// positionY: e.pageY,
					destroy: true,
					onShow: '',
					onShown: '',
					onHide: '',
					onHidden: '',
					show: true,
				};

				utilities.popover(
					popover,
					'<div ng-include="\'app/activation/update-dialog.html\'"></div>'
				);
			};

			var userLicenseCallback = function () {
				var popover = {
					id: 'license',
					controller: 'ActivationCtrl',
					container: $('body'),
					type: 'modal', // modal or popover
					width: 750,
					// positionX: e.pageX,
					// positionY: e.pageY,
					destroy: true,
					onShow: '',
					onShown: '',
					onHide: '',
					onHidden: '',
					show: true,
				};

				utilities.popover(
					popover,
					'<div ng-include="\'app/activation/license-exceeded.html\'"></div>'
				);
			};

			var deactivatedCallback = function () {
				var popover = {
					id: 'deactivated',
					controller: 'ActivationCtrl',
					container: $('body'),
					type: 'modal', // modal or popover
					width: 750,
					// positionX: e.pageX,
					// positionY: e.pageY,
					destroy: true,
					onShow: '',
					onShown: '',
					onHide: '',
					onHidden: '',
					show: true,
				};

				utilities.popover(
					popover,
					'<div ng-include="\'app/activation/deactivated.html\'"></div>'
				);
			};

			//Create callback object containing all of the callback functions for verifying the hash
			var callbacks = {
				successCallback: successCallback,
				expiredCallback: expiredCallback,
				overdueCallback: overdueCallback,
				trialCallback: trialCallback,
				updateCallback: updateCallback,
				userLicenseCallback: userLicenseCallback,
				deactivatedCallback: deactivatedCallback,
			};

			var today = new Date();
			today.setHours(0, 0, 0, 0);
			today = today.getTime();

			var currentMonth = moment().month();

			if (platform !== 'dbksf') {
				// Verify session count
				window.setTimeout(function () {
					checkActiveSessions();
				}, 2000);
			}

			if (platform === 'dbksf') {
				hashResult = {};
				hashResult.type =
					activationObj && activationObj.state
						? typeFromState(activationObj.state, true)
						: typeFromState(null, false);
				hashResult.manuallyDeactivated =
					activationObj && activationObj.manuallyDeactivated
						? activationObj.manuallyDeactivated
						: null;

				hashResult.planLevel = getPlanLevel();

				if (hashResult.manuallyDeactivated) {
					crypt.runVerifyActivation(activationObj, true, callbacks);
				}

				if (callback) {
					callback(hashResult);
				}
				return;
			} else if (activationObj) {
				hashResult = {};
				// activated: crypt.runGetHashProperty(hashResult, 'level') == 3 ? true : false,
				hash.isChildActivation =
					!!urlParameters.parentSubscriptionID &&
					!!urlParameters.parentCustomerID;

				hashResult.manuallyDeactivated =
					activationObj.manuallyDeactivated;

				hashResult.userID = activationObj.userID;
				hashResult.state = activationObj.state;
				hashResult.isSubscription = activationObj.isSubscription
					? true
					: false;
				hashResult.subscriptionID = activationObj.subscriptionID;

				hashResult.expiresDate = moment(
					activationObj.currentPeriodEndsAt
				)
					.hours(0)
					.minutes(0)
					.valueOf();
				hashResult.cutoffDate = moment(
					activationObj.currentPeriodEndsAt
				)
					.hours(0)
					.minutes(0)
					.valueOf();

				if (
					activationObj.state === 'active' &&
					hashResult.expiresDate +
						expiresDateNotificationBuffer * 24 * 60 * 60 * 1000 <
						today
				) {
					// Let's call a mixpanel operation to record that a subscription hasn't updated for some reason
					//Update mixpanel analytics after a short delay
					window.setTimeout(function () {
						var trackProperties = {};
						trackProperties['Subscription Issue Type'] =
							'Date Mismatch';
						trackProperties['Renewal Date'] =
							activationObj.currentPeriodEndsAt;
						trackProperties['Client Date'] = moment().format();
						utilities.trackMixPanel(
							'Subscription Issue',
							trackProperties
						);
					}, 5000);
				}

				hashResult.trialStart = activationObj.trialStart;
				hashResult.userLimit = Number(activationObj.usersPermitted);
				hashResult.freeUserLimit = Number(
					activationObj.freeUsersPermitted || 0
				);
				hashResult.hasActivationObject = true;

				hashResult.activated =
					activationObj.state && activationObj.state !== 'trialing';

				hashResult.productDisplay = activationObj.productName;
				hashResult.productName = activationObj.productHandle;
				hashResult.name = activationObj.organization
					? activationObj.organization
					: activationObj.name;

				hashResult.planLevel = getPlanLevel(
					activationObj.productHandle
				);
				hashResult.billingPortalURL = activationObj.billingPortalURL;
				hashResult.billingPortalURLExpiration =
					activationObj.billingPortalURLExpiration;

				hashResult.type = typeFromState(
					activationObj.state,
					!!hashResult.subscriptionID
				);

				//If our expiration data hasn't been met but there is no subscription ID then expire the trial
				if (
					activationObj.state !== 'trialing' &&
					!activationObj.subscriptionID
				) {
					hashResult.expiresDate = moment()
						.subtract('days', 1)
						.valueOf();
					hashResult.cutoffDate = moment()
						.subtract('days', 1)
						.valueOf();
				}

				if (hashResult.freeUserLimit) {
					hashResult.userLimit =
						hashResult.userLimit + hashResult.freeUserLimit;
				}

				// Set early access to features (good for enabling access to new sources)
				hashResult.earlyAccess = activationObj.earlyAccess;

				hash.set(hashResult);

				crypt.runVerifyActivation(
					activationObj,
					hashResult.manuallyDeactivated,
					callbacks
				);

				if (callback) {
					callback(hashResult);
				}

				return;
			}

			//Verify the hash and save the result to a var
			hashResult = crypt.runVerifyHash(hashToVerify, callbacks);

			//Get additional hash details
			var activated =
				crypt.runGetHashProperty(hashResult, 'level') == 3
					? true
					: false;
			var version = crypt.runGetHashProperty(hashResult, 'version');
			var previousVersion = crypt.runGetHashProperty(
				hashResult,
				'previousVersion'
			);
			var restoreDirectory = crypt.runGetHashProperty(
				hashResult,
				'restoreDirectory'
			);
			var upgradeDirectory = crypt.runGetHashProperty(
				hashResult,
				'upgradeDirectory'
			);
			var isSubscription =
				crypt.runGetHashProperty(hashResult, 'type') === 'subscription'
					? true
					: false;
			var subscriptionID = crypt.runGetHashProperty(
				hashResult,
				'subscriptionID'
			);
			var subscriptionURL = crypt.runGetHashProperty(
				hashResult,
				'subscriptionURL'
			);
			var reference = crypt.runGetHashProperty(hashResult, 'reference');
			var productDisplay = crypt.runGetHashProperty(
				hashResult,
				'productDisplay'
			);
			var productName = crypt.runGetHashProperty(
				hashResult,
				'productName'
			);
			var expiresDate = Number(
				crypt.runGetHashProperty(hashResult, 'expiresDate')
			);
			var cutoffDate = Number(
				crypt.runGetHashProperty(hashResult, 'cutoffDate')
			);
			var orderNumber = crypt.runGetHashProperty(
				hashResult,
				'orderNumber'
			);
			var email = crypt.runGetHashProperty(hashResult, 'email');
			var name = crypt.runGetHashProperty(hashResult, 'name');
			var userLimit = Number(
				crypt.runGetHashProperty(hashResult, 'userLimit')
			);

			var type = crypt.runGetHashProperty(hashResult, 'type');
			var hashDate = Number(
				crypt.runGetHashProperty(hashResult, 'hashDate')
			);

			//Expire if the hashDate is greater than today - This would mean someone is setting their date in the past to get around expiration date
			if (today < hashDate) {
				hashDate = today;
				expiresDate = today;
			}

			if (
				(type === 'subscription' && !expiresDate) ||
				(type === 'subscription' &&
					!subscriptionVerified &&
					today >= expiresDate)
			) {
				verifySubscription(
					hashResult,
					reference,
					subscriptionID,
					callback
				);
			} else {
				hashResult = {
					hash: hashResult,
					activated:
						crypt.runGetHashProperty(hashResult, 'level') == 3
							? true
							: false,
					version: crypt.runGetHashProperty(hashResult, 'version'),
					previousVersion: crypt.runGetHashProperty(
						hashResult,
						'previousVersion'
					),
					restoreDirectory: crypt.runGetHashProperty(
						hashResult,
						'restoreDirectory'
					),
					upgradeDirectory: crypt.runGetHashProperty(
						hashResult,
						'upgradeDirectory'
					),
					isSubscription:
						crypt.runGetHashProperty(hashResult, 'type') ===
						'subscription'
							? true
							: false,
					subscriptionID: crypt.runGetHashProperty(
						hashResult,
						'subscriptionID'
					),
					subscriptionURL: crypt.runGetHashProperty(
						hashResult,
						'subscriptionURL'
					),
					reference: crypt.runGetHashProperty(
						hashResult,
						'reference'
					),
					productDisplay: crypt.runGetHashProperty(
						hashResult,
						'productDisplay'
					),
					productName: crypt.runGetHashProperty(
						hashResult,
						'productName'
					),
					type: crypt.runGetHashProperty(hashResult, 'type'),
					expiresDate: Number(
						crypt.runGetHashProperty(hashResult, 'expiresDate')
					),
					cutoffDate: Number(
						crypt.runGetHashProperty(hashResult, 'cutoffDate')
					),
					trialStart: trialStart,
					orderNumber: crypt.runGetHashProperty(
						hashResult,
						'orderNumber'
					),
					email: crypt.runGetHashProperty(hashResult, 'email'),
					name: crypt.runGetHashProperty(hashResult, 'name'),
					changed: changed,
					userLimit: Number(
						crypt.runGetHashProperty(hashResult, 'userLimit')
					),
				};
				hash.set(hashResult);

				if (callback) {
					callback(hashResult);
				}
			}
		}

		//Write our hash back to the backend for storage
		function writeHash(hash, platform) {
			var restoreDirectory =
				crypt.runGetHashProperty(hash, 'restoreDirectory') || '';
			var upgradeDirectory =
				crypt.runGetHashProperty(hash, 'upgradeDirectory') || '';
			var subscriptionURL =
				crypt.runGetHashProperty(hash, 'subscriptionURL') || '';
			var reference = crypt.runGetHashProperty(hash, 'reference') || '';
			manageSettings.setHash(
				hash,
				restoreDirectory,
				upgradeDirectory,
				subscriptionURL,
				platform
			);
		}

		function activate(orderNumber, email, name, viewCallback) {
			var successCallback = function (hashData) {
				verifyHash(hashData, true, null, viewCallback);
			};
			var errorCallback = function (error) {
				viewCallback(error);
			};

			crypt.runActivation(
				orderNumber,
				email,
				name,
				successCallback,
				errorCallback
			);
		}

		function verifySubscription(
			hashData,
			referenceID,
			subscriptionID,
			callback
		) {
			var successCallback = function (hashResult) {
				subscriptionVerified = true; //Set this to true so we don't continue to try and verify subscription once we have already done it.
				verifyHash(hashResult, true, null, callback);
			};
			var errorCallback = function (error) {
				subscriptionVerified = true;
				verifyHash(hashData, true, null, callback, true);
			};
			//Run verification routine
			crypt.runVerifySubscription(
				hashData,
				referenceID,
				subscriptionID,
				successCallback,
				errorCallback
			);
		}

		function checkHashExpired(
			hash,
			platform,
			verifiedCallback,
			expiredCallback
		) {
			var callbacks = {
				successCallback: function (newHash) {
					//Check if we need to update the hash - Would happen if this is the first time accessing
					if (newHash !== hash) {
						writeHash(newHash, platform);
					}
					//Run our callback
					verifiedCallback(newHash);
				},
				expiredCallback: expiredCallback,
			};

			crypt.runVerifyHash(hash, callbacks, platform, true);
		}

		function resetUserLicense(hash) {
			var newHash = crypt.runResetUserLicense(hash);
			writeHash(newHash);
		}

		function checkForUpdate(url) {
			return $http.get(url).then(function (result) {
				return result.data;
			});
		}

		function getUpgradeDirectory(url) {
			return $http.get(url).then(function (result) {
				return result.data;
			});
		}
	}
})();
